<template>
  <div id="award-category-list">
    <v-card>
      <v-card-title>
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col align="right" cols="12" md="12" lg="12">
            <router-link to="/jci-award/judges/upload">
              <v-btn
                color="#8950FC"
                elevation="30"
                rounded
                medium
                class="font-size-h6 px-4 mr-3 white--text"
              >
                &nbsp; Upload &nbsp;
              </v-btn>
            </router-link>

            <v-btn
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              rounded
              medium
              class="font-size-h6 px-4 mr-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle> -->
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Zone</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap class="mt-0">
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="#1db954"
                  class="font-size-h6 px-5 mr-3 white--text"
                >
                  Search
                </v-btn>
              </v-col>
              <v-col cols="12" md="12" class="text-center mt-0">
                <h3 class="text-danger">
                  {{ tableData1.length }} records found.
                </h3>
              </v-col>
              <v-col cols="12" md="12" class="text-center mt-0">
                <json-excel
                  v-if="tableData1.length > 0 && DownloadFlag"
                  :name="ExcelFileName"
                  :data="tableData1"
                  :fields="ExcelFields"
                >
                  <v-btn color="info" class="mr-4"> Download </v-btn>
                </json-excel>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-container class="py-0" v-if="tableData1.length > 0">
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected1"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :show-select="tableOptions1.ShowSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ContactTxt="{ item }">
                  <p v-html="item.ContactTxt"></p>
                </template>
                <template v-slot:item.MemberImagePath="{ item }">
                  <img width="100" height="100" :src="item.MemberImagePath" />
                </template>
                <template v-slot:item.Actions="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditButtonFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Reset Active Status </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteButtonFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#ff0000"
                        @click="deleteAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      LoadingFlag: false,
      SubmitFlag: false,
      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid: true,

      RoleId: "",

      ResultFlag: 0,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordData: {},

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getZoneCodeOptions();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("ZoneCode called");
      this.resetForm();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "award_judges",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    resetForm() {
      console.log("resetForm called");
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        NotAvailable: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm called");

      var result = this.$refs.form1.validate();
      console.log("result=" + result);

      if (result) {
        this.SubmitFlag = true;
        this.LoadingFlag = true;
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judge/lists";
        var upload = {
          UserInterface: 1,
          Zone: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;

            thisIns.tableOptions1 = options;

            console.log("tableOptions1 =" + JSON.stringify(options));

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert(1);
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert(2);
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert(id) {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt +=
          "<br/>Organization Type <b> " + tr.OrganizationTypeName + "</b>";
        htmlTxt += "<br/>Judge Name <b> " + tr.MemberFullNameTxt + "</b>";
        htmlTxt += "<br/>Judge MobileNo <b> " + tr.MobileNo + "</b>";
        htmlTxt += "<br/>Judge EmailId <b> " + tr.EmailId + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: id == 1 ? `Reset Active Status` : `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            if (id == 1) {
              this.ResetJudgeStatus(tr);
            } else {
              this.deleteRecord(tr);
            }
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    ResetJudgeStatus(tr) {
      console.log("ResetJudgeStatus called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judge/reset-active-status";
        var upload = {
          UserInterface: 1,
          Judge: tr.JudgeId,
          User: tr.UserId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.submitForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to reset status";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judge/destroy";
        var upload = {
          UserInterface: 1,
          Judge: tr.JudgeId,
          JudgeName: tr.JudgeName,
          User: tr.UserId,
          Role: tr.RoleId,
          Zone: tr.ZoneId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.submitForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss"></style>
